<template>
  <div class="omoss-container">
    <div class="text-container">
    <h2>Om oss</h2>
    <p>

    Välkommen till The Lobby, Vasastans hjärta för gott käk, goda drinkar och sköna vibes! Efter en lång dag är The Lobby det perfekta stället för att koppla av och dela skratt med vänner.
    Oavsett om du är här för en lugn middag, en kväll med vännerna eller en romantisk dejt, finns något för alla på vår meny. 
    Vårt mål är att skapa en plats där du känner dig som hemma, där du kan njuta av god mat, kalla drinkar och en avslappnad atmosfär. Så, kom och häng med oss!
    </p>

</div>
  </div>
</template>
