<template>
<div>
  <div class="imgbanner-container">
    <img src="../../assets/mainbanner-background.jpg"/>
    <div class="img-caption">
    <div>
      <h1>The Lobby</h1>
      <h3>Bar och restaurang på vasastan</h3>
      </div>

    </div>
  </div>
</div>
</template>