<template>
  <div class="aw-container">
    <div class="drinkmenu-container">
      <div v-for="(item, index) in drinks" :key="index" class="menuitems">
        <div class="menu-item-info">
          <h4>{{ item.name }}</h4>
        </div>
        <div class="menu-item-price">
          <h4>{{ item.price }}</h4>
        </div>
      </div>
    </div>

    <div class="foodmenu-container px-3">

    <div class="foodmenu">
    <div class="foodmenu-inner-container">
      <div v-for="(item, index) in food" :key="index" class="menuitems">
        <div class="menu-item-info">
            <h4>{{ item.name }}</h4>
            <p>{{ item.description }}</p>       
        </div>
      </div>
    <div class="text-center"> 
        <h2>159:-</h2>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drinks: [
        { name: 'STOR STARK 0,4L', price: '39 :-' },
        { name: 'VIN', price: '59 :-' },
        { name: 'cider', price: '59 :-' },
        { name: 'CAVA', price: '79 :-' },
        { name: 'ALKOHOLFRI FR.', price: '35 :-' }
      ],
      food: [
        { name: 'HUSETS PLANKSTEK', description: 'Fläskfilé, duchesse potatis, sparris, tomat, bearnaisesås, rödvinssky'},
        { name: 'HAMBURGARE', description: 'Dressing, cheddarost, sallad, tomat, rödlök, aioli, pommes'},
        { name: 'köttbullar', description: 'Delikata köttbullar med gräddsås, saltgurka, rårörda lingon & potatismos'},
        { name: 'ceasarsallad', description: 'Grillad kyckling, romansallad, krispigt bacon, krutonger, grana padano, cocktailtomater & ceasardressing'},
      ],
    };
  },
};
</script>
