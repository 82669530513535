<template>
  <div class="aw-container py-5">
      <div v-for="(section, sectionIndex) in menusections" :key="sectionIndex" class="menu-section">
        <div class="foodmenu-headline">
          <h2>{{ section.heading }}</h2>
        </div>
            <div class="matmeny-container">
        <div v-for="(item, itemIndex) in section.items" :key="itemIndex" class="foodmenu-item">
          <div class="foodmenu-item-info">
            <h4>{{ item.name }}</h4>
            <p>{{ item.desc }}</p>
          </div>
          <div class="foodmenu-item-price">
            <h4>{{ item.price }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                menusections: [
                    {
                    heading: 'Förrätter',
                    items: [
                    {name: 'Vitlöksbröd', desc: '', price: '75:-'},
                    {name: 'Toast Skagen', desc: 'Klassisk hemmagjord skagenröra på rostat bröd', price: '139:-'},
                    {name: 'Chili och vitlöksfrästa Scampi', price: '139:-'},
                    {name: 'Chicken wings', desc: '6st/12st. Marinerade kycklingvingar med hot tigersås', price: '99:-/169:-'},
                    {name: 'Jalapeño poppers', desc: '6st/12st. Friterade färska ostfyllda chillipeppar, serveras med salsa', price: '99:-/169:-'},
                    {name: 'Mozarella sticks', desc: '6st/12 st. Friterade mozarella sticks, serveras med salsa', price: '99:-/169:-'},
                    {name: 'Nachos', desc: 'Nacho chips serveras med salsa, guacamole och gräddfil', price: '99:-'},
                    {name: 'Pommes med bea-sås', price: '75:-'},
                    ]
                    },
                    {
                    heading: 'Varmrätter', 
                    items: [
                    {name: 'Schnitzel (fläsk)', desc: 'Serveras med pommes, kapris, citron, rödvinssky & bea-sås', price: '199:-'},
                    {name: 'Hamburgare', desc: 'Angusburgare med dressing, cheddarost, sallad, tomat & röd lök. Serveras med pommes & aioli.', price: '195:-'},
                    {name: 'Halloumiburgare', desc: 'Halloumiburgare med dressing, sallad, tomat & röd lök. Serveras med pommes & aioli', price: '195:-'},
                    {name: 'Tryffelburgare', desc: 'Tryffelburgare med dressing, cheddarost, sallad, bacon, jalapeños, tomat & röd lök. Serveras med pommes & chilli-bea.', price: '199:-'},
                    {name: 'Grillad oxfilé', desc: 'Serveras med pommes, stekta grönsaker, rödvinssky & bea-sås', price: '329:-'},
                    {name: 'Köttbullar', desc: 'Serveras med potatismos, gräddsås, saltgurka & rårörda lingon', price: '199:-'},
                    {name: 'Kycklingfilé', desc: 'Serveras med pommes, stekta grönsaker, rödvinssky & bea-sås', price: '199:-'},
                    {name: 'Filé oscar', desc: 'Grillad fläskfilé. Serveras med pommes, sparris, handskalade räkor, rödvinssky & bea-sås', price: '199:-'}                 
                    ]
                    },
                    {
                    heading: 'Plankstek',
                    items: [
                    {name: 'Klassisk planka', desc: 'Grillad oxfilé. Serveras med duchesse potatis, baconlindad sparris, stekta grönsaker, rödvinssky & bea-sås', price: '329:-'},
                    {name: 'Fläskfilé planka', desc: 'Grillad fläskfilé. Serveras med duchesse potatis, baconlindad sparris, stekta grönsaker, rödvinssky & bea-sås', price: '229:-'}
                    ]
                    }, 
                    {
                    heading: 'Fisk',
                    items: [
                    {name: 'Fish & chips', desc: 'Serveras med pommes & remouladsås', price: '199:-'},
                    ]
                    },
                    {
                    heading: 'Sallader',
                    items: [
                    {name: 'Ceasarsallad (Kyckling)', desc: 'Grillad Kyckling, romansallad, krispig bacon, krutonger, grana padano, cocktailtomater & ceasardessing', price: '189:-'},
                    {name: 'Ceasarsallad (Scampi)', desc: 'Chili & vitlöksfräst Scampi, romansallad, krutonger, grana padano, cocktailtomater & ceasardressing', price: '199:-'},
                    {name: 'Halloumisallad', desc: 'Halloumi, romansallad, krutonger, grana padano, cocktailtomater & aioli', price: '189:-'},
                    {name: 'Räksallad', desc: 'Handskalade räkor, romansallad, krutonger, grana padano, cocktailtomater, citron & rhode island', price: '199:-'}
                    ]
                    },
                    {
                    heading: 'Pasta',
                    items: [
                    {name: 'Oxfilépasta', desc: 'För vegetarisk alternativ - välj bort oxfilé. Strimlad oxfilé, tryffel, svartpeppar, lök, chili, vitlök, grana padano, cocktailtomater & grädde', price: '199:-'},
                    {name: 'Pasta Carbonara', desc: 'Bacon, lök, svartpeppar, grädde & grana padano', price: '189:-'},
                    {name: 'Scampi Pasta', desc: 'Scampi, vitt vin, sparris, chili, vitlök, tomat & persilja', price: '199:-'}
                    ]
                    },
                    {
                    heading: 'Barnmeny',
                    items: [
                    {name: 'Fläskfilé', desc: 'Serveras med pommes, rödvinssky & bea-sås', price: '129:-'},
                    {name: '4 st köttbullar', desc: 'Serveras med pommes & ketchup', price: '129:-'}
                    ]
                    },
                    {
                    heading: 'Dessert',
                    items: [
                    {name: 'Kladdkaka med grädde', price: '89:-'},
                    {name: 'Vaniljglass med jordgubbssås', price: '89:-'}
                    ]
                    }
                ]
            }
        }
    }
</script>
