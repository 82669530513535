<template>
    <div>
        <MainBanner />
        <OmOss />
        <BoxSection />
        <ImageSection />
    </div>
</template>

<script>
import BoxSection from '@/components/Home/BoxSection.vue'
import MainBanner from '../components/Home/MainBanner.vue'
import OmOss from '../components/Home/OmOss.vue'
import ImageSection from '../components/Home/ImageSection.vue'

  export default {
    components: {
        MainBanner, OmOss, BoxSection, ImageSection
    }
  }
</script>