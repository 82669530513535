<template>
    <div>
        <MainBanner />
        <KontaktaOss />
    </div>
</template>

<script>
import KontaktaOss from '@/components/Kontakt/KontaktaOss.vue'
import MainBanner from '../components/Kontakt/HeroBanner.vue'

  export default {
    components: {
        MainBanner, KontaktaOss
    }
  }
</script>