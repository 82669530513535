<template>
  <div class="boxsection-container">
    <!-- Bildbakgrund -->

    <!-- Flex-container för att placera boxarna ovanpå bilden -->
    <div class="flex-container">
      <!-- Box 1 -->
      <div class="text-box" id="box1">
        <h3>afterwork</h3>
        <p class="pb-3">Arbetsdagen är över, och nu är det dags för dig eller er att koppla av. Vår atmosfär efter jobbet är perfekt för avslappnade stunder med kollegor och vänner.</p>
        <a href="/afterwork" class="primary-button text-decoration-none">AW MENY</a>
      </div>

      <!-- Box 2 -->
      <div class="text-box" id="box2">
        <h3>mat</h3>
        <p class="pb-3">Vår Matmeny är full av läckerheter för alla smaker. Från saftiga burgare till vegetariska läckerheter. Inga konstigheter, bara god mat och trevligt sällskap.</p>
        <a href="/meny/matmeny" class="primary-button text-decoration-none">MATMENY</a>
      </div>

      <!-- Box 3 -->
      <div class="text-box" id="box3">
        <h3>bar</h3>
        <p class="pb-3">Kom in och häng med oss vid baren! Njut av en kall öl, en av alla våra goda drinkar eller bara slappna av efter en lång dag. Det finns alltid plats för dig här.</p>
        <a href="/meny/drinklista" class="primary-button text-decoration-none">DRINKLISTA</a>
      </div>
    </div>
  </div>
</template>
