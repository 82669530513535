<template>
<div>
  <div class="aw-imgbanner-container">
    <img src="../../assets/aw-herobanner.png"/>
    <div class="img-caption">
    <div>
      <h1>Afterwork</h1>
      <h3>Alla dagar till 20:00</h3>
      </div>

    </div>
  </div>
</div>
</template>