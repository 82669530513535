<template>
    <div>
        <MainBanner />
        <AwMenu/>
    </div>
</template>

<script>
import MainBanner from '../components/Afterwork/HeroBanner.vue'
import AwMenu from '../components/Afterwork/AwMenu.vue'

  export default {
    components: {
        MainBanner, AwMenu
    }
  }
</script>