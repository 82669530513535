<template>
<div>
  <div class="contact-container mx-auto">
    <div class="contact-form">
      <form @submit.prevent="submitForm">
        <div class="input-cont">
          <input type="text" v-model="formData.name" id="name" name="name" placeholder="Namn*" required>
        </div>

        <div class="input-cont">
          <div class="input-group">
            <div class="email-input">
              <input type="email" id="email" v-model="formData.email" name="email" placeholder="E-post*" required>
            </div>
            <div class="phone-input">
              <input type="phone" id="phone" v-model="formData.phone" name="phone" placeholder="Telefon*" required>
            </div>
          </div>
        </div>

        <div class="input-cont">
          <textarea v-model="formData.message" id="message" name="message" placeholder="Ditt meddelande*" required></textarea>
        </div>

        <div>
          <button type="submit" class="primary-button">Skicka</button>
        </div>
      </form>
    <div>
      {{ successMessage }}
    </div>
  </div>

    

    <div class="contact-info">
      <!-- Kontaktuppgifter -->
      <div class="pb-5 pt-3">
        <h4>Våra öppettider</h4>
            <p>Måndag till Torsdag 14:00 - 01:00</p>
            <p>Fredag 12:00-01:00</p>
            <p>Lördag 11:00-01:00</p>
            <p>Söndag 12:00-01:00</p>
            
      </div>
      <div>
      <h4>kontaktuppgifter</h4>
      <p>Telefon: 076-584 26 28</p>
      <p>E-post: info@thelobbysthlm.nu</p>
      <p>Adress: Vasagatan 44, 111 20 Stockholm</p>
      </div>
    </div>
  </div>

<div style="width: 100%"><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=the%20lobby+(the%20lobby)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population Estimator map</a></iframe></div>

</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        email: '',
        message: ''
      },
      //errorMsg: '',
      successMessage: '', 
    };
  },
  methods: {
    async submitForm() {

      //console.log('Form submitted:', this.formData);
      
      try {

          const response = await axios.post('/.netlify/functions/contact', this.formData, {
                  headers: {
          'Content-Type': 'application/json',
        }
        });
        
          console.log(response)

          this.successMessage = 'Tack för ditt meddelande! Vi återkommer så fort som möjligt.';

          this.formData = {
            name: '',
            phone: '',
            email: '',
            message: '',
          };

      } catch (error) {
        console.error(error);
        this.errorMsg = 'Error, try again later!'
      }
    },
    handleChange() {
      //this.errorMsg = null;
      this.successMessage = '';
    },
  }
};
</script>


