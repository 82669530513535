<template>
  <nav :class="{ 'navbar-fixed': mobileMenuOpen }" class="navbar">
    <div class="navbar-left">
      <a href="/" class="navbar-logo">The Lobby</a>
    </div>
    <div class="navbar-right" v-if="!isMobile">
      <a class="mx-5" 
        :class="{ active: $route.path === '/afterwork' }"
        href="/afterwork">
          Afterwork
      </a>

      <!-- Custom Dropdown Menu -->
      <div class="custom-dropdown">
        <div class="dropdown-trigger" @click="toggleDropdown">
          <a :class="{ active: ['/meny/matmeny', '/meny/drinklista'].includes($route.path) }" class="pe-2" >Menyer</a>
          <img src="../assets/arrow-icon.svg" />
        </div>
        <div class="dropdown-content" v-show="isDropdownOpen" :class="{ open: isDropdownOpen }">
          <a :class="{ active: $route.path === '/meny/matmeny' }" class="px-4 py-2" href="/meny/matmeny">Matmeny</a>
          <a :class="{ active: $route.path === '/meny/drinklista' }" class="px-4 py-2" href="/meny/drinklista">Drinklista</a>
        </div>
      </div>

      <a class="mx-5" 
        :class="{ active: $route.path === '/kontaktaoss' }"
        href="/kontaktaoss">
          Kontakt
      </a>
    </div>

    <div class="navbar-right-mobile bg-black" v-if="isMobile">
      <a @click="toggleMobileMenu" class="burger-menu">
        <img v-if="!mobileMenuOpen" src="../assets/burger.svg" />
        <img v-if="mobileMenuOpen" src="../assets/cross-icon.svg" />
      </a>
      <div v-if="mobileMenuOpen" class="mobile-menu">
        <div class="pb-5">
        <router-link @click="closeMobileMenu" to="/afterwork">Afterwork</router-link>
        </div>
        <div class="pb-5">
          <div class="dropd" @click="toggleDropdown">
            <a>Menyer</a>
            <img class="ps-2" src="../assets/arrow-icon.svg" />
          </div>
          <div class="dropdownmobile-content" v-show="isDropdownOpen" :class="{ open: isDropdownOpen }">
            <a href="/meny/matmeny">Matmeny</a>
            <a href="/meny/drinklista">Drinklista</a>
          </div>
        </div>
        <router-link @click="closeMobileMenu" class="pb-5" to="/kontaktaoss">Kontakt</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isDropdownOpen: false,
      isMobile: false,
      mobileMenuOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      console.log("clicked")
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false
    }
  },
    mounted() {
    // Check if the screen width is less than a certain value (e.g., 768px) to determine if it's a mobile view
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth < 600;

    // Listen for resize events to toggle between mobile and desktop view
    window.addEventListener('resize', () => {
    this.isMobile = window.innerWidth < 768;
    });
  }
}
</script>

<style scoped>
.navbar-right a.active {
  color: #F2922A !important;
}


.navbar-right-mobile {
  display: flex;
  flex-direction: column !important;
}

.navbar-right-mobile img {
  cursor: pointer;
}

.mobile-menu {
  background-color: black;
  text-align: center !important;
  display: flex;
  flex-direction: column !important;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  position: fixed; /* Set position to fixed */
  top: 50px; /* Set the top position */
  justify-content: center !important;
  right: 0;
  left: 0;
  height: 100%;
}

.mobile-menu a {
  font-size: 20px;
  font-family: 'NHaas65';
  text-align: center;
}

</style>