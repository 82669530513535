<template>
  <div class="drink-container py-5">
      <div v-for="(section, sectionIndex) in menusections" :key="sectionIndex" class="menu-section">
        <div class="foodmenu-headline">
          <h2>{{ section.heading }}</h2>
        </div>
            <div class="matmeny-container">
        <div v-for="(item, itemIndex) in section.items" :key="itemIndex" class="foodmenu-item">
          <div class="foodmenu-item-info">
            <h4>{{ item.name }}</h4>
            <p>{{ item.desc }}</p>
          </div>
          <div class="foodmenu-item-price pe-2">
            <h4>{{ item.price }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                menusections: [
                    {
                    heading: 'Läsk/alkoholfritt',
                    items: [
                    {name: 'pepsi (30cl)', price: '39:-'},
                    {name: 'pepsi max (30cl)', price: '39:-'},
                    {name: 'zingo (30cl)', price: '39:-'},
                    {name: '7up (30cl)', price: '39:-'},
                    {name: 'mineralvatten', price: '39:-'},
                    {name: 'bryggkaffe', price: '39:-'},
                    {name: 'alkoholfri öl (33cl)', price: '59:-'},
                    {name: 'ett glas alkoholfritt vin', desc: 'Rött eller vitt', price: '79:-'},
                    ]
                    },
                    {
                    heading: 'Fatöl',
                    items: [
                    {name: 'Grängesberg', desc: '', price: '59:-'},
                    {name: 'Norrlands guld', desc: '', price: '69:-'},
                    {name: 'A ship full of ipa', price: '94:-'},
                    {name: 'Krusovice imperial', desc: '', price: '94:-'},
                    ]
                    },
                    {
                    heading: 'Flasköl', 
                    items: [
                    {name: 'Mariestad (33cl)', desc: 'mellanöl', price: '69:-'},
                    {name: 'Sol (33cl)', desc: '', price: '69:-'},
                    {name: 'Menabrea', desc: '', price: '69:-'},
                    {name: 'Heiniken', desc: '', price: '69:-'},
                    {name: 'Sleepy bulldog', desc: '', price: '69:-'},
                    {name: 'Daura damm', desc: 'Glutenfri', price: '69:-'},
                    {name: 'Mariestad', desc: 'Export', price: '89:-'},
                    {name: 'veteöl', desc: '', price: '99:-'}            
                    ]
                    },
                    {
                    heading: 'Cider/breezer',
                    items: [
                    {name: 'Briska', desc: 'Päron/fläder/hallon', price: '79:-'},
                    {name: 'Strongbow', desc: '', price: '79:-'},
                    {name: 'Smirnoff ice', desc: '', price: '79:-'},
                    {name: 'Breezer', desc: 'Vattenmelon/mango', price: '79:-'}
                    ]
                    }, 
                    {
                    heading: 'Rött/vitt/rosé vin',
                    items: [
                    {name: 'Husets glas', desc: '', price: '84:-'},
                    {name: 'Husets flaska', desc: '', price: '369:-'},
                    {name: 'Husets flaska (AW)', desc: '', price: '329:-'}
                    ]
                    },
                    {
                    heading: 'Mouserrande',
                    items: [
                    {name: 'ett glas cava', desc: '', price: '99:-'},
                    {name: 'en flaska cava', desc: '', price: '409:-'},
                    ]
                    },
                    {
                    heading: 'drinkar/cocktails (5cl)',
                    items: [
                    {name: 'jack & coke', desc: 'Jack daniels och coca cola', price: '149:-'},
                    {name: 'gin & tonic', desc: '', price: '139:-'},
                    {name: 'cuba libre', desc: 'Rom, cola och lime', price: '139:-'},
                    {name: 'vodka energy', desc: 'Vodka och energidryck', price: '139:-'},
                    {name: 'jägermeister energy', desc: 'Jägermeister och energidryck', price: '139:-'},
                    {name: 'white russian', desc: 'Vodka, kahlua och mjölk', price: '149:-'},
                    {name: 'solero', desc: 'Likör 43, passion juice och mjölk', price: '149:-'},
                    {name: 'rosa pantern', desc: 'Likör 43, vodka, mjölk, grenadine, grädde och strössel', price: '149:-'},
                    {name: 'pina colada', desc: 'Ljus rom, malibu, ananas juice och mjölk', price: '149:-'},
                    {name: 'margarita', desc: 'Tequila, cointreau, limejuice och sockerlag', price: '149:-'},
                    {name: 'cosmopolitan', desc: 'Citronvodka, cointreau och tranbärsjuice', price: '149:-'},
                    {name: 'whiskey sour', desc: 'Whiskey, angostura bitter, sockerlag, äggvita och citronjuice', price: '149:-'},
                    {name: 'amaretto sour', desc: 'Disaronno, angostura bitter, sockerlag, äggvita och citronjuice', price: '149:-'}, 
                    {name: 'skinny beach', desc: 'Vodka, soda och lime', price: '149:-'},
                    {name: 'twister', desc: 'Bacardi limon, limejuice, strawberry puree och sprite', price: '149:-'},
                    {name: 'p2', desc: 'Vaniljvodka, sourz sour apple och sprite', price: '149:-'},
                    {name: 'aperol spritz', desc: 'Prosecco, aperol, soda och apelsin', price: '149:-'},
                    {name: 'blue lagoon', desc: 'Vodka, bolsblue och sprite', price: '149:-'},
                    {name: 'sex on the beach', desc: 'Peachtree, vodka, transbärsjuice och apelsinjuice', price: '149:-'},
                    {name: 'expresso martini', desc: 'Kaluha, vodka, bryggkaffe och sockerlag', price: '149:-'},
                    {name: 'irish coffee', desc: 'Jameson, bryggkaffe, grädde och farinsocker', price: '149:-'},
                    {name: 'baileys coffee', desc:'Baileys, bryggkaffe och grädde', price: '149:-'},
                    {name: 'kaffe karlsson', desc: 'Baileys, cointreau, bryggkaffe och grädde', price: '149:-'} 
                    ]
                    },
                    {
                    heading: 'shots (5cl)',
                    items: [
                    {name: 'hot shots', price: '119:-'},
                    {name: 'tequila', price: '109:-'},                    
                    {name: 'minttu', desc:'Choklad eller pepparmint', price: '109:-'},
                    {name: 'jägermeister', price: '109:-'},
                    {name: 'sambuca', price: '109:-'},
                    {name: 'fisherman', price: '109:-'},
                    {name: 'lakrits', price: '109:-'},
                    {name: 'fernet branca', price: '109:-'},
                    {name: 'fernet menta', price: '109:-'},
                    {name: 'gammeldansk', price: '109:-'},
                    {name: 'fireball', price: '109:-'},
                    {name: 'sour fisk', desc:'Cola, hallon/lakrits eller päron', price: '109:-'}
                    ]
                    },
                    {
                    heading: 'Whiskey/cognac',
                    items: [
                    {name: 'jameson', price: '25:-/cl'},
                    {name: 'famous grouse', price: '25:-/cl'},
                    {name: 'jack daniels', price: '25:-/cl'},
                    {name: 'jack daniels honey', price: '25:-/cl'},
                    {name: 'jim beam bourbon', price: '25:-/cl'},
                    {name: 'black label', price: '32:-/cl'},
                    {name: 'bowmore 12Y', price: '35:-/cl'},
                    {name: 'laphroaig 10y', price: '39:-/cl'},
                    {name: 'makers mark', price: '39:-/cl'},
                    {name: 'macallan 12y', price: '39:-/cl'},
                    {name: 'lagavulin 12y', price: '49:-/cl'},
                    {name: 'blue label', price: '99:-/cl'},
                    {name: 'cognac braastad', price: '29:-/cl'},
                    {name: 'cognac meukow', price: '35:-/cl'}
                    ]
                    },
                ]
            }
        }
    }
</script>