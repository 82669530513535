<template>
    <div>
        <MainBanner />
        <MatMeny />
    </div>
</template>

<script>
import MainBanner from '../components/FoodMenu/HeroBanner.vue'
import MatMeny from '../components/FoodMenu/MatMeny.vue'

  export default {
    components: {
        MainBanner, MatMeny
    }
  }
</script>