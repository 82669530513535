<template>
    <div>
        <MainBanner />
        <DrinkMeny />
    </div>
</template>

<script>
import MainBanner from '../components/Drinkmenu/HeroBanner.vue'
import DrinkMeny from '../components/Drinkmenu/DrinkMenu.vue'

  export default {
    components: {
        MainBanner, DrinkMeny
    }
  }
</script>