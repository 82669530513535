import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../styles/style.css'
import StartSida from './pages/StartSida.vue'
import AfterWork from './pages/AfterWork.vue'
import MatMeny from './pages/MatMeny.vue'
import DrinkLista from './pages/DrinkLista.vue'
import KontaktaOss from './pages/KontaktaOss.vue'


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL || '/'),
    routes: [
        {path: '/', name: 'Startsida', component: StartSida},
        {path: '/afterwork', name: 'Afterwork', component: AfterWork},
        {path: '/meny/matmeny', name: 'Matmeny', component: MatMeny},
        {path: '/meny/drinklista', name: 'Drinklista', component: DrinkLista},
        {path: '/kontaktaoss', name: 'Kontakt', component: KontaktaOss},
        {path: '/:catchAll(.*)', redirect: '/'}
    ]
})

createApp(App).use(router).mount('#app')