<template>
<div class="imagesection-container">
  <div class="container-xxl">
    <div class="main-image-container">
      <div class="images-container">
      <div class="img-cont i1">
        <img src="../../assets/img-section1.png" alt="Image 1">
      </div>
      <div class="img-cont i2">
        <img src="../../assets/img-section4.png" alt="Image 2">
      </div>
      <div class="img-cont i3">
        <img src="../../assets/img-section3.png" alt="Image 3">
      </div>
      <div class="img-cont i4">
        <img src="../../assets/img-section2.png" alt="Image 4">
      </div>
    </div>
    </div>
  </div>

</div>
</template>